import { nanoid } from "nanoid";
import { dashToEmptySpace } from "./dashToEmptySpace";
//import SmartToyIcon from "@mui/icons-material/SmartToy";
import {
  analyticsIcon,
  // commentBlockIcon,
  //clients,
  helpDeskIcon,
  logOutIcon,
  messagesIcon,
  file,
  // location,
  eye,
  // categories,
  // vector,
  fileedit,
  openusers,
  calendar,
  // commentquestion,
  // close,
  // edit,
  plusCircleIcon,
  settingsIcon,
  todoListIcon,
  userCircleIcon,
  account,
  helpdesk,
  // notification,
  departmentsIcon,
  inbox,
  assigned,
  pack,
  users,
  shortcutmessages,
  // chakkebAlBacIcon,
  // mloulLelekherIcon,
  // silverIcon,
  // ipIcon,
  // googleIcon,
  editIcon,
  //envelopeIcon,
  clipboardIcon,
  trashIcon,
  bellIcon,
  archiveIcon,
  // colorSetting,
  websiteConfig,
  //offersIcon,
  technicalSupport,
  angryFace,
  disappointedFace,
  expressionLessFace,
  grinningFace,
  smilingFace,
  eyeIcon,
  botConfigIcon,
} from "../assets";
export default {
  APPBAR_MOBILE: 64,
  APPBAR_DESKTOP: 40,
  DRAWER_WIDTH: 239,
  APP_BAR_MOBILE: 20,
  APP_BAR_DESKTOP: 50,
  HEADER_DIVIDER_WIDTH: 32.6,

  OPTIONS: [
    // { value: "ADMIN", label: "admin" },
    { value: "OPERATOR", label: "operator" },
  ],
  FEEDBACKS: [
    { id: 1, expression: angryFace },
    { id: 2, expression: disappointedFace },
    { id: 3, expression: expressionLessFace },
    { id: 4, expression: grinningFace },
    { id: 5, expression: smilingFace },
  ],
  MANAGE_SHORTCUTS: [
    { id: nanoid(), title: "Service Vente" },
    { id: nanoid(), title: "Customer Service" },
  ],
  SHORTCUT_OPTIONS: [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ],
  Time_Filter: [
    { value: "5", label: "last 5 minutes" },
    { value: "60", label: "last 1 hour" },
    { value: "180", label: "last 3 hours" },
    { value: "300", label: "last 5 hours" },
    { value: "480", label: "last 8 hours" },
  ],
  NAV_CONFIG: [
    {
      id: nanoid(),
      title: "Inbox",
      path: "/messaging",
      icon: messagesIcon,
    },
    {
      id: nanoid(),
      title: "To Do List",
      path: "/todo-list",
      icon: clipboardIcon,
      children: [
        {
          id: nanoid(),
          title: "Open",
          path: "/todo-list/Open",
          icon: inbox,
        },
        {
          id: nanoid(),
          title: "Assigned",
          path: "/todo-list/assigned",
          icon: assigned,
        },
        {
          id: nanoid(),
          title: "Complete",
          path: "/todo-list/complete",
          icon: pack,
        },
      ],
    },
    {
      id: nanoid(),
      title: "Helpdesk",
      path: "/help-desk",
      icon: helpDeskIcon,
      children: [
        {
          id: nanoid(),
          title: "Articles",
          path: "/help-desk/articles",
          icon: file,
        },
        // {
        //   id: nanoid(),
        //   title: "Categories",
        //   path: "/help-desk/categories",
        //   icon: categories,
        // },
        // {
        //   id: nanoid(),
        //   title: "Published",
        //   path: "/help-desk/published",
        //   icon: location,
        // },
        // {
        //   id: nanoid(),
        //   title: "Draft",
        //   path: "/help-desk/draft",
        //   icon: fileedit,
        // },
        {
          id: nanoid(),
          title: "Visible",
          path: "/help-desk/visible",
          icon: eye,
        },
      ],
    },
    {
      id: nanoid(),
      title: "Analytics",
      path: "/analytics",
      icon: analyticsIcon,
    },
    {
      id: nanoid(),
      title: "Settings",
      path: "/settings",
      icon: settingsIcon,
      children: [
        {
          id: nanoid(),
          title: "Account",
          subtitle: "Profile,Name,Email,Password",
          path: "/settings/account",
          icon: account,
        },

        {
          id: nanoid(),
          title: "Users",
          subtitle: "Manage Your Team",
          path: "/settings/users",
          role: ["ADMIN", "SUPER-ADMIN"],
          icon: users,
        },
        {
          id: nanoid(),
          title: "Clients",
          subtitle: "Manage Your Clients",
          path: "/settings/clients",
          icon: users,
        },
        {
          id: nanoid(),
          title: "Departments",
          subtitle: "Create Your Departments",
          path: "/settings/departments",
          icon: departmentsIcon,
        },
        {
          id: nanoid(),
          title: "Shortcut Message",
          subtitle: "Manage Your Saved Shortcut",
          path: "/settings/shortcut-message",
          icon: shortcutmessages,
        },
        {
          id: nanoid(),
          title: "Helpdesk",
          subtitle: "Manage Your helpdesk",
          path: "/settings/helpdesk",
          icon: helpdesk,
        },
      ],
    },
    {
      id: nanoid(),
      title: "Bot",
      path: "/bot",
      icon: technicalSupport,
    },
    {
      id: nanoid(),
      title: "Notifications",
      path: "/notifications",
      icon: bellIcon,
    },
    {
      id: nanoid(),
      title: "Archived",
      path: "/archived",
      icon: archiveIcon,
    },
  ],
  BOTOPTIONS: [
    { value: "select", label: "select" },
    { value: "checkBox", label: "checkBox" },
    { value: "input", label: "input" },
    { value: "message", label: "message" },
  ],

  LANGUAGES: [
    {
      value: "fr",
      label: "Français",
      country_code: "fr",
    },
    {
      value: "en",
      label: "English",
      country_code: "gb",
    },
    {
      value: "ar",
      label: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
    {
      value: "de",
      label: "Deutsch",
      country_code: "de",
    },
    {
      value: "es",
      label: "Español",
      country_code: "es",
    },
    // { value: "fr", label: "French" },
    // { value: "en", label: "English" },
    // { value: "ar", label: "Arabic" },
  ],

  FILTERS: [
    { id: nanoid(), label: "Filter 1" },
    { id: nanoid(), label: "Filter 2" },
    { id: nanoid(), label: "Filter 3" },
    { id: nanoid(), label: "TFilter 4" },
    { id: nanoid(), label: "Filter 5" },
    { id: nanoid(), label: "Filter 6" },
    { id: nanoid(), label: "Filter 7" },
  ],
  HELPDESK_ROUTES: [
    {
      id: nanoid(),
      title: "Articles",
      path: "/help-desk/articles",
      icon: file,
    },
    // {
    //   id: nanoid(),
    //   title: "Categories",
    //   path: "/help-desk/categories",
    //   icon: categories,
    // },
    // {
    //   id: nanoid(),
    //   title: "Published",
    //   path: "/help-desk/published",
    //   icon: location,
    // },

    {
      id: nanoid(),
      title: "Visible",
      path: "/help-desk/visible",
      icon: fileedit,
    },
    {
      id: nanoid(),
      title: "Draft",
      path: "/help-desk/draft",
      icon: fileedit,
    },
  ],
  TODOLIST_ROUTES: [
    {
      id: nanoid(),
      title: "Open",
      path: "/todo-list/open",
      icon: inbox,
    },
    {
      id: nanoid(),
      title: "Assigned",
      path: "/todo-list/assigned",
      icon: assigned,
    },
    {
      id: nanoid(),
      title: "Complete",
      path: "/todo-list/complete",
      icon: pack,
    },
  ],

  accountPopoverConfig: [
    {
      id: nanoid(),
      icon: userCircleIcon,
      alt: "user-circle",
      title: "Edit Account",
      onClick: "/settings/account",
    },
    {
      id: nanoid(),
      icon: plusCircleIcon,
      alt: "plus-circle",
      title: "Invite Your Team",
      onClick: "/",
    },
    {
      id: nanoid(),
      icon: logOutIcon,
      alt: "log-out",
      title: "Logout",
    },
  ],

  INVITATIONS_LIST: [
    {
      id: "Members",
      title: "Full Members",
    },
    {
      id: "PendingInvites",
      title: "Pending Invites",
    },
    // {
    //   id: "Approval",
    //   title: "Pending For Approval",
    // },
  ],
  ARTICLES_STATUS: [
    {
      value: "DRAFT",
      label: "DRAFT",
    },
    {
      value: "VISIBLE",
      label: "VISIBLE",
    },
    // {
    //   value: "HIDDEN",
    //   label: "HIDDEN",
    // },
  ],
  TABLE_HEAD: [
    { id: "OperatorId", label: "OperatorId", alignRight: false },
    { id: "Full Name", label: "Full Name", alignRight: false },
    { id: "Email", label: "Email", alignRight: false },
    { id: "Role", label: "Role", alignRight: false },
    { id: "phone Number", label: "Phone Number", alignRight: false },
  ],
  PENDING_TABLE_HEAD: [
    { id: "OperatorId", label: "OperatorId", alignRight: false },
    { id: "Full Name", label: "Full Name", alignRight: false },
    { id: "Email", label: "Email", alignRight: false },
    { id: "Role", label: "Role", alignRight: false },
  ],
  options: {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  SETTINGS: [
    {
      id: nanoid(),
      title: "Account",
      subtitle: "Profile,Name,Email,Password",
      path: "/settings/account",
      icon: account,
    },
    {
      id: nanoid(),
      title: "Users",
      subtitle: "Manage Your Team",
      path: "/settings/users",
      icon: users,
    },

    {
      id: nanoid(),
      title: "Clients",
      subtitle: "Manage Your Clients",
      path: "/settings/clients",
      icon: users,
    },
    {
      id: nanoid(),
      title: "Departments",
      subtitle: "Create Your Departments",
      path: "/settings/departments",
      icon: departmentsIcon,
    },
    {
      id: nanoid(),
      title: "Shortcut Message",
      subtitle: "Manage Your Saved Shortcut",
      path: "/settings/shortcut-message",
      icon: shortcutmessages,
    },
    {
      id: nanoid(),
      title: "Website-configuration",
      subtitle:
        "Customize Your Website Configuration (Color , Chosen languages)",
      path: "/settings/website-configuration",
      icon: websiteConfig,
    },
    {
      id: nanoid(),
      title: "Bot Configuration",
      subtitle: "Configurate Your Chat Bot Settings",
      path: "/settings/chatbot-configuration",
      icon: botConfigIcon,
    },
    // {
    //   id: nanoid(),
    //   title: "Question",
    //   subtitle: "Create Bot Question",
    //   path: "/settings/question",
    //   icon: helpdesk,
    // },
  ],

  FILTER_CARDS_MESSAGES: [
    { id: 0, title: "All" },
    { id: 1, title: "Unread" },
    { id: 2, title: "Unresolved" },
    { id: 3, title: "Resolved" },
    { id: 4, title: "Bot" },
    { id: 5, title: "WhatsApp" },
    { id: 6, title: "Messenger" },
  ],

  REPLIES_CONTROLLERS: [
    { id: 1, text: "Reply" },
    { id: 2, text: "Note" },
    { id: 3, text: "Reminder" },
    { id: 4, text: "Shortcuts" },
    { id: 5, text: "Helpdesk" },
  ],
  ISSUES: [
    {
      id: "assignedto",
      title: dashToEmptySpace("Assigned-to:"),
      img: openusers,
      answer: "Empty",
    },
    {
      id: "duedate",
      title: dashToEmptySpace(`Due-Date:`),
      img: calendar,
      answer: "Empty",
    },
    // { id: "tags", title: `Tags:`, img: edit, answer: "Empty" },
  ],

  TAGS: [
    { id: nanoid(), title: "welcome", color: "#ED3863" },
    { id: nanoid(), title: "Chat", color: "#7BC600" },
    { id: nanoid(), title: "Issue", color: "#F3941B" },
    { id: nanoid(), title: "Account Problem", color: "#04C9E4" },
    { id: nanoid(), title: "New Client", color: "#38A1ED" },
    { id: nanoid(), title: "Discount", color: "#fec423" },
  ],

  ACCOUNT_INFO: {
    Creation: "08:09 PM | 17/05/2022",
    Update: "01:43 PM | 23/05/2022",
  },

  HOVER_MESSAGE_ICONS: [
    { id: nanoid(), icon: editIcon },
    { id: nanoid(), icon: eyeIcon },
    { id: nanoid(), icon: clipboardIcon },
    { id: nanoid(), icon: trashIcon },
  ],
  ANALYTICS_FILTERS: [
    { id: nanoid(), label: "Day", selected: "day" },
    { id: nanoid(), label: "Week", selected: "week" },
    { id: nanoid(), label: "Month", selected: "month" },
    { id: nanoid(), label: "Year", selected: "year" },
  ],
  OPTIONS_DATE: {},
  FAKE_SKELETONS: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
  ISSUE_DETAILS_LIST: [
    {
      id: "Description",
      title: "Description",
    },
    {
      id: "Comments",
      title: "Comments",
    },
    {
      id: "History",
      title: "History",
    },
  ],
  BACKGROUND_COLORS: [
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
    "#FEC423",
    "#124E8A",
  ],
  HOURS_LABELS: [
    [
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
      "00:00",
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ],
  FRENCH_MONTHS_LABEL: [
    [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ],
  ARABIC_MONTHS_LABEL: [
    [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ],
  ENGLISH_MONTHS_LABEL: [
    [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ],
  FRENCH_DAYS_LABEL: [
    ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
    [0, 0, 0, 0, 0, 0, 0],
  ],
  ARABIC_DAYS_LABEL: [
    ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"],
    [0, 0, 0, 0, 0, 0, 0],
  ],
  ENGLISH_DAYS_LABEL: [
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    [0, 0, 0, 0, 0, 0, 0],
  ],
  ENGLISH_DAYS_BOT: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    ,
  ],
  days: [
    { title: "Monday", frenchTitle: "Lundi", arabicTitle: "الاثنين", value: 0 },
    {
      title: "Tuesday",
      frenchTitle: "Mardi",
      arabicTitle: "الثلاثاء",
      value: 1,
    },
    {
      title: "Wednesday",
      frenchTitle: "Mercredi",
      arabicTitle: "الأربعاء",
      value: 2,
    },
    {
      title: "Thursday",
      frenchTitle: "Jeudi",
      arabicTitle: "الخميس",
      value: 3,
    },
    {
      title: "Friday",
      frenchTitle: "Vendredi",
      arabicTitle: "الجمعة",
      value: 4,
    },
    {
      title: "Saturday",
      frenchTitle: "Samedi",
      arabicTitle: "السبت",
      value: 5,
    },
    {
      title: "Sunday",
      frenchTitle: "Dimanche",
      arabicTitle: "الأحد",
      value: 6,
    },
  ],
};
